<template>
  <div
    class="brands_management"
    style="overflow: hidden; padding: 5px 10px 10px 10px"
  >
    <AddBrands :getMedicaments="getMedicaments" />
    <div v-if="!isLoading" class="Container Flipped">
      <table class="Content">
        <tr>
          <th v-for="item in table_header" :key="item.id">
            {{ item }}
          </th>
        </tr>
        <tr
          v-for="(medicaments, index) in allMedicaments"
          :key="medicaments.id"
        >
          <td class="show_on_front">
            <i v-if="medicaments.show == true" class="fal fa-check-circle"></i>
            <i v-if="medicaments.show == false" class="fal fa-ban"></i>
          </td>
          <td>
            <div v-if="lang == 'ka'" v-html="medicaments.titleKA"></div>
            <div v-else v-html="medicaments.titleEN"></div>
          </td>
          <td>
            <a
              style="color: black"
              target="_blank"
              download
              :href="`${server_url}/${medicaments.attachments.attachment}`"
            >
              <i style="font-size: 20px" class="fad fa-cloud-download"></i>
              {{ $t("medicamentsDescription.download") }}
            </a>
          </td>
          <td>
            <hooper
              :progress="true"
              :autoPlay="true"
              :playSpeed="3000"
              :hover="true"
              style="border-radius: 0px; width: 150px"
            >
              <slide v-for="item in medicaments.images" :key="item.id">
                <div class="conatiner" style="height: 100px; width: 150px">
                  <img
                    style="height: 100px; width: 150px"
                    :src="`${server_url}/${item.image}`"
                    :alt="item"
                  />
                </div>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="medicaments.additionalInformation.internationalTitleKA"
            ></div>
            <div
              v-else
              class="short_text"
              v-html="medicaments.additionalInformation.internationalTitleEN"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="
                medicaments.additionalInformation.ClinicAndPharmacyGroupKA
              "
            ></div>
            <div
              v-else
              class="short_text"
              v-html="
                medicaments.additionalInformation.ClinicAndPharmacyGroupEN
              "
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="medicaments.additionalInformation.ownerCompanyKA"
            ></div>
            <div
              v-else
              class="short_text"
              v-html="medicaments.additionalInformation.ownerCompanyEN"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="medicaments.additionalInformation.ownerCountryKA"
            ></div>
            <div
              v-else
              class="short_text"
              v-html="medicaments.additionalInformation.ownerCountryEN"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="medicaments.additionalInformation.ReleaseFormKA"
            ></div>
            <div
              v-else
              class="short_text"
              v-html="medicaments.additionalInformation.ReleaseFormEN"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="medicaments.additionalInformation.issuanceKA"
            ></div>
            <div
              v-else
              class="short_text"
              v-html="medicaments.additionalInformation.issuanceEN"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="medicaments.category.titleKA"
            ></div>
            <div
              v-else
              class="short_text"
              v-html="medicaments.category.titleEN"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              :ref="'productDescriptionKA' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              :ref="'productDescriptionEN' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              :ref="'displayKA' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              :ref="'displayEN' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              :ref="'compositionKA' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              :ref="'compositionEN' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="medicaments.termsAndConditionKA"
            ></div>
            <div
              v-else
              class="short_text"
              v-html="medicaments.termsAndConditionEN"
            ></div>
          </td>
          <td>
            <div v-for="item in medicaments.keywords" :key="item.id">
              {{ item }}
            </div>
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editMedicaments(
                    medicaments._id,
                    medicaments.images,
                    medicaments.show,
                    medicaments.category,
                    medicaments.titleKA,
                    medicaments.titleEN,
                    medicaments.additionalInformation.internationalTitleKA,
                    medicaments.additionalInformation.internationalTitleEN,
                    medicaments.additionalInformation.ClinicAndPharmacyGroupEN,
                    medicaments.additionalInformation.ClinicAndPharmacyGroupKA,
                    medicaments.additionalInformation.ownerCompanyKA,
                    medicaments.additionalInformation.ownerCompanyEN,
                    medicaments.additionalInformation.ownerCountryKA,
                    medicaments.additionalInformation.ownerCountryEN,
                    medicaments.additionalInformation.ReleaseFormKA,
                    medicaments.additionalInformation.ReleaseFormEN,
                    medicaments.additionalInformation.issuanceKA,
                    medicaments.additionalInformation.issuanceEN,
                    medicaments.productDescriptionEN,
                    medicaments.productDescriptionKA,
                    medicaments.compositionEN,
                    medicaments.compositionKA,
                    medicaments.displayKA,
                    medicaments.displayEN,
                    medicaments.termsAndConditionEN,
                    medicaments.termsAndConditionKA,
                    medicaments.keywords
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
          <td>
            <ConfirmDelete
              @clicktodelete="deleteMedicaments(medicaments._id)"
            />
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="7"
        :columns="10"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("admin.title") }} (KA)</label>
        <b-form-input v-model="update.titleKA"></b-form-input>
        <label for="">{{ $t("admin.title") }} (EN)</label>
        <b-form-input v-model="update.titleEN"></b-form-input>
      </div>
      <div class="text_editor">
        <label for="">
          {{ $t("add_exclusibe_Brands.internationalTitle") }} (KA)
        </label>
        <vue-editor
          v-model="update.additionalInformation.internationalTitleKA"
        ></vue-editor>
        <label for="">
          {{ $t("add_exclusibe_Brands.internationalTitle") }} (EN)
        </label>
        <vue-editor
          v-model="update.additionalInformation.internationalTitleEN"
        ></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">
          {{ $t("add_exclusibe_Brands.clinicAndPharmacyGroup") }} (KA)
        </label>
        <vue-editor
          v-model="update.additionalInformation.ClinicAndPharmacyGroupKA"
        ></vue-editor>
        <label for=""
          >{{ $t("add_exclusibe_Brands.clinicAndPharmacyGroup") }} (EN)</label
        >
        <vue-editor
          v-model="update.additionalInformation.ClinicAndPharmacyGroupEN"
        ></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_exclusibe_Brands.ownerCompany") }} (KA)</label>
        <vue-editor
          v-model="update.additionalInformation.ownerCompanyKA"
        ></vue-editor>
        <label for="">{{ $t("add_exclusibe_Brands.ownerCompany") }} (EN)</label>
        <vue-editor
          v-model="update.additionalInformation.ownerCompanyEN"
        ></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_exclusibe_Brands.ownerCountry") }} (KA)</label>
        <vue-editor
          v-model="update.additionalInformation.ownerCountryKA"
        ></vue-editor>
        <label for="">{{ $t("add_exclusibe_Brands.ownerCountry") }} (EN)</label>
        <vue-editor
          v-model="update.additionalInformation.ownerCountryEN"
        ></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_exclusibe_Brands.releaseForm") }} (KA)</label>
        <vue-editor
          v-model="update.additionalInformation.ReleaseFormKA"
        ></vue-editor>
        <label for="">{{ $t("add_exclusibe_Brands.releaseForm") }} (EN)</label>
        <vue-editor
          v-model="update.additionalInformation.ReleaseFormEN"
        ></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_exclusibe_Brands.issuance") }} (KA)</label>
        <vue-editor
          v-model="update.additionalInformation.issuanceKA"
        ></vue-editor>
        <label for="">{{ $t("add_exclusibe_Brands.issuance") }} (EN)</label>
        <vue-editor
          v-model="update.additionalInformation.issuanceEN"
        ></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">
          {{ $t("add_exclusibe_Brands.productDescription") }} (KA)
        </label>
        <vue-editor v-model="update.productDescriptionKA"></vue-editor>
        <label for=""
          >{{ $t("add_exclusibe_Brands.productDescription") }} (EN)</label
        >
        <vue-editor v-model="update.productDescriptionEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_exclusibe_Brands.composition") }} (KA)</label>
        <vue-editor v-model="update.compositionKA"></vue-editor>
        <label for="">{{ $t("add_exclusibe_Brands.composition") }} (EN)</label>
        <vue-editor v-model="update.compositionEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_exclusibe_Brands.display") }} (KA)</label>
        <vue-editor v-model="update.displayKA"></vue-editor>
        <label for="">{{ $t("add_exclusibe_Brands.display") }} (EN)</label>
        <vue-editor v-model="update.displayEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for=""
          >{{ $t("add_exclusibe_Brands.termsAndCondition") }} (KA)</label
        >
        <vue-editor v-model="update.termsAndConditionKA"></vue-editor>
        <label for=""
          >{{ $t("add_exclusibe_Brands.termsAndCondition") }} (EN)</label
        >
        <vue-editor v-model="update.termsAndConditionEN"></vue-editor>
      </div>
      <div class="text_editor" v-if="update.category !== null">
        <label for="">{{ $t("add_category.selected_category") }}</label>
        <input class="input" v-model="update.category.titleKA" readonly />
      </div>
      <div>
        <label for="">{{ $t("admin_sidebar.category") }}</label>
        <select name="" id="" v-model="update.category" required>
          <option
            :value="category.id"
            v-for="category in allCategory"
            :key="category.id"
          >
            <span v-if="lang == 'ka'"> {{ category.nameKA }}</span>
            <span v-else> {{ category.nameEN }}</span>
          </option>
        </select>
      </div>
      <div class="text_editor" v-if="update.keywords.length !== 0">
        <label for=""> {{ $t("admin.choosed_keywords") }}</label>
        <div
          v-for="(item, index) in update.keywords"
          :key="index"
          class="d-flex"
        >
          <div class="choosed_keywords">
            {{ item }}
          </div>
          <i
            class="fal fa-times-square"
            style="cursor: pointer; margin-left: 20px; font-size: 20px"
            @click="delete_keywords(index)"
          ></i>
        </div>
      </div>
      <div class="text_editor add_comp">
        <label for=""> {{ $t("admin.keywords") }}</label>
        <i class="fal fa-plus-square" @click="add_component()"></i>
        <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
          <input
            class="input add_component"
            type="text"
            v-model="comp[i]"
            @change="get_value($event)"
          />
          <i class="fal fa-times-square delete_comp" @click="delete_comp"></i>
        </div>
      </div>
      <div>
        <label for="">{{ $t("admin.activate_on_front") }}</label>
      </div>
      <input class="checkbox" type="checkbox" v-model="update.show" />
      <hooper class="hooper_edit" :itemsToShow="3" :itemsToSlide="3">
        <slide style="width: 160px" v-for="item in images" :key="item._id">
          <div class="upload_image">
            <img
              :src="`${server_url}/${item.image}`"
              alt="await for new image to upload"
              class="image"
            />
            <div class="overlay">
              <div class="icon d-flex">
                <input
                  type="file"
                  accept="image/jpg, image/png, image/jpeg"
                  multiple
                  id="file"
                  class="input"
                  @change="editImage($event, item._id)"
                />
                <i
                  class="fal fa-trash"
                  @click="
                    deleteImage(
                      item._id,
                      update.show,
                      update.category,
                      update.titleKA,
                      update.titleEN,
                      update.additionalInformation.internationalTitleKA,
                      update.additionalInformation.internationalTitleEN,
                      update.additionalInformation.ClinicAndPharmacyGroupEN,
                      update.additionalInformation.ClinicAndPharmacyGroupKA,
                      update.additionalInformation.ownerCompanyKA,
                      update.additionalInformation.ownerCompanyEN,
                      update.additionalInformation.ownerCountryKA,
                      update.additionalInformation.ownerCountryEN,
                      update.additionalInformation.ReleaseFormKA,
                      update.additionalInformation.ReleaseFormEN,
                      update.additionalInformation.issuanceKA,
                      update.additionalInformation.issuanceEN,
                      update.productDescriptionEN,
                      update.productDescriptionKA,
                      update.compositionEN,
                      update.compositionKA,
                      update.displayKA,
                      update.displayEN,
                      update.termsAndConditionEN,
                      update.termsAndConditionKA,
                      update.keywords
                    )
                  "
                ></i>
              </div>
            </div>
          </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
      <input
        type="file"
        style="margin: 10px 0"
        accept="image/jpg, image/png, image/jpeg"
        class="input"
        multiple
        @change="previewFiles($event)"
      />
      <label for="">{{ $t("admin.file") }}</label>
      <input
        type="file"
        style="margin: 10px 0"
        class="input"
        @change="attachFile($event)"
      />
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateMedicaments()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "hooper/dist/hooper.css";
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import AddBrands from "../AddComponents/AddExclusiveBrands.vue";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  name: "getTeam",
  components: {
    AddBrands,
    VueEditor,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    spinner,
    ConfirmDelete,
  },
  data() {
    return {
      modalShow: false,
      table_header: [
        this.$t("admin.active"),
        this.$t("admin.title"),
        this.$t("admin.file"),
        this.$t("admin.image"),
        this.$t("add_exclusibe_Brands.internationalTitle"),
        this.$t("add_exclusibe_Brands.clinicAndPharmacyGroup"),
        this.$t("add_exclusibe_Brands.ownerCompany"),
        this.$t("add_exclusibe_Brands.ownerCountry"),
        this.$t("add_exclusibe_Brands.releaseForm"),
        this.$t("add_exclusibe_Brands.issuance"),
        this.$t("admin_sidebar.category"),
        this.$t("add_exclusibe_Brands.productDescription"),
        this.$t("add_exclusibe_Brands.display"),
        this.$t("add_exclusibe_Brands.composition"),
        this.$t("add_exclusibe_Brands.termsAndCondition"),
        this.$t("admin.keywords"),
        this.$t("admin.edit"),
        this.$t("admin.delete"),
      ],
      allMedicaments: [],
      allCategory: [],
      checkedItems: [],
      active_id: "",
      image_id: "",
      images: null,
      update: {
        show: false,
        keywords: [],
        titleKA: "",
        titleEN: "",
        additionalInformation: {
          internationalTitleKA: "",
          internationalTitleEN: "",
          ClinicAndPharmacyGroupEN: "",
          ClinicAndPharmacyGroupKA: "",
          ownerCompanyKA: "",
          ownerCompanyEN: "",
          ownerCountryKA: "",
          ownerCountryEN: "",
          ReleaseFormKA: "",
          ReleaseFormEN: "",
          issuanceEN: "",
          issuanceKA: "",
        },
        productDescriptionEN: "",
        productDescriptionKA: "",
        compositionEN: "",
        compositionKA: "",
        displayKA: "",
        displayEN: "",
        termsAndConditionEN: "",
        termsAndConditionKA: "",
        category: "",
      },
      attach_file: null,
      file_name: null,
      edit_image: null,
      components: [{ comp: "comp" }],
      server_url: env.server_url,
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getMedicaments();
    this.getCategory();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    attachFile(e) {
      const file = e.target.files[0];
      const fileName = e.target.files[0].name;
      this.attach_file = file;
      this.file_name = fileName;
    },
    previewFiles(e) {
      const image = e.target.files;
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.images = image;
    },
    editImage(e, id) {
      const image = e.target.files;
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.image_id = id;
      this.edit_image = image;
    },
    getCategory() {
      axios.get(`${env.host}/get/all/categories/admin`).then((res) => {
        res.data.item.map((item) => {
          this.allCategory.push({
            nameKA: item.titleKA,
            nameEN: item.titleEN,
            id: item._id,
          });
        });
      });
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    delete_keywords(index) {
      if (index > -1) {
        this.update.keywords.splice(index, 1);
      }
    },
    get_value(e) {
      this.update.keywords.push(e.target.value);
    },
    getMedicaments() {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/all/products/${null}/${null}/${null}`)
        .then((res) => {
          this.isLoading = false;
          res.data.item.map((medicaments, index) => {
            this.$nextTick(() => {
              this.$refs["productDescriptionKA" + index].forEach((element) => {
                element.insertAdjacentHTML(
                  "beforeend",
                  medicaments.productDescriptionKA
                );
              });
              this.$refs["displayKA" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", medicaments.displayKA);
              });
              this.$refs["compositionKA" + index].forEach((element) => {
                element.insertAdjacentHTML(
                  "beforeend",
                  medicaments.compositionKA
                );
              });
            });
            this.$nextTick(() => {
              this.$refs["productDescriptionEN" + index].forEach((element) => {
                element.insertAdjacentHTML(
                  "beforeend",
                  medicaments.productDescriptionEN
                );
              });
              this.$refs["displayEN" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", medicaments.displayEN);
              });
              this.$refs["compositionEN" + index].forEach((element) => {
                element.insertAdjacentHTML(
                  "beforeend",
                  medicaments.compositionEN
                );
              });
            });
          });
          this.allMedicaments = res.data.item;
        });
    },
    editMedicaments(
      id,
      images,
      show,
      category,
      titleKA,
      titleEN,
      internationalTitleKA,
      internationalTitleEN,
      ClinicAndPharmacyGroupEN,
      ClinicAndPharmacyGroupKA,
      ownerCompanyKA,
      ownerCompanyEN,
      ownerCountryKA,
      ownerCountryEN,
      ReleaseFormKA,
      ReleaseFormEN,
      issuanceKA,
      issuanceEN,
      productDescriptionEN,
      productDescriptionKA,
      compositionEN,
      compositionKA,
      displayKA,
      displayEN,
      termsAndConditionEN,
      termsAndConditionKA,
      keywords
    ) {
      this.active_id = id;
      console.log(this.active_id);
      this.images = images;
      this.update.show = show;
      this.update.category = category._id;
      this.update.titleKA = titleKA;
      this.update.titleEN = titleEN;
      this.update.additionalInformation.internationalTitleKA =
        internationalTitleKA;
      this.update.additionalInformation.internationalTitleEN =
        internationalTitleEN;
      this.update.additionalInformation.ClinicAndPharmacyGroupEN =
        ClinicAndPharmacyGroupEN;
      this.update.additionalInformation.ClinicAndPharmacyGroupKA =
        ClinicAndPharmacyGroupKA;
      this.update.additionalInformation.ownerCompanyKA = ownerCompanyKA;
      this.update.additionalInformation.ownerCompanyEN = ownerCompanyEN;
      this.update.additionalInformation.ownerCountryKA = ownerCountryKA;
      this.update.additionalInformation.ownerCountryEN = ownerCountryEN;
      this.update.additionalInformation.ReleaseFormKA = ReleaseFormKA;
      this.update.additionalInformation.ReleaseFormEN = ReleaseFormEN;
      this.update.additionalInformation.issuanceKA = issuanceKA;
      this.update.additionalInformation.issuanceEN = issuanceEN;

      this.update.productDescriptionEN = productDescriptionEN;
      this.update.productDescriptionKA = productDescriptionKA;
      this.update.compositionEN = compositionEN;
      this.update.compositionKA = compositionKA;
      this.update.displayKA = displayKA;
      this.update.displayEN = displayEN;
      this.update.termsAndConditionEN = termsAndConditionEN;
      this.update.termsAndConditionKA = termsAndConditionKA;
      this.update.keywords = keywords;
    },
    UpdateMedicaments() {
      this.isLoading = true;
      console.log(this.active_id);
      axios
        .post(`${env.host}/edit/products/${this.active_id}`, this.update)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getMedicaments();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });

      console.log(this.update);
      if (!Array.isArray(this.images)) {
        for (let i = 0; i < this.images?.length; i++) {
          const formData = new FormData();
          formData.append("image", this.images[i]);
          axios
            .post(
              `${env.host}/add/image/products/${this.active_id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                icon: "success",
                text: this.$t("success.success_edit"),
              });
              this.isLoading = false;
              this.modalShow = false;
              setTimeout(() => {
                this.getMedicaments();
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      if (this.edit_image !== null) {
        this.images = this.edit_image;
        for (let i = 0; i < this.images?.length; i++) {
          const formData = new FormData();
          formData.append("image", this.images[i]);
          axios
            .post(
              `${env.host}/edit/image/products/${this.active_id}/${this.image_id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                icon: "success",
                text: this.$t("success.success_edit"),
              });
              this.isLoading = false;
              this.modalShow = false;
              setTimeout(() => {
                this.getMedicaments();
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      const formData_attach = new FormData();
      formData_attach.append("fileName", this.file_name);
      formData_attach.append("attachment", this.attach_file);
      axios
        .post(
          `${env.host}/add/attachment/products/${this.active_id}`,
          formData_attach,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getMedicaments();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteImage(
      id,
      show,
      category,
      titleKA,
      titleEN,
      internationalTitleKA,
      internationalTitleEN,
      ClinicAndPharmacyGroupEN,
      ClinicAndPharmacyGroupKA,
      ownerCompanyKA,
      ownerCompanyEN,
      ownerCountryKA,
      ownerCountryEN,
      ReleaseFormKA,
      ReleaseFormEN,
      issuanceKA,
      issuanceEN,
      productDescriptionEN,
      productDescriptionKA,
      compositionEN,
      compositionKA,
      displayKA,
      displayEN,
      termsAndConditionEN,
      termsAndConditionKA,
      keywords
    ) {
      this.isLoading = true;
      axios
        .delete(`${env.host}/delete/image/products/${this.active_id}/${id}`)
        .then(() => {
          axios
            .get(`${env.host}/get/all/products/${null}/${null}/${null}`)
            .then((res) => {
              res.data.item.map((item) => {
                this.editMedicaments(
                  this.active_id,
                  item.images,
                  show,
                  category,
                  titleKA,
                  titleEN,
                  internationalTitleKA,
                  internationalTitleEN,
                  ClinicAndPharmacyGroupEN,
                  ClinicAndPharmacyGroupKA,
                  ownerCompanyKA,
                  ownerCompanyEN,
                  ownerCountryKA,
                  ownerCountryEN,
                  ReleaseFormKA,
                  ReleaseFormEN,
                  issuanceKA,
                  issuanceEN,
                  productDescriptionEN,
                  productDescriptionKA,
                  compositionEN,
                  compositionKA,
                  displayKA,
                  displayEN,
                  termsAndConditionEN,
                  termsAndConditionKA,
                  keywords
                );
                this.isLoading = false;
              });
            });
        });
    },
    deleteMedicaments(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/products/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete"),
        });
        this.isLoading = false;
        this.getMedicaments();
      });
    },
  },
};
</script>

<style scoped>
.brands_management {
  width: 100%;
}
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
input#file {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 40px 0 0 0;
  height: 10px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
    center center no-repeat #e4e4e4;
  border-radius: 5px;
  background-size: 30px 30px;
}
.hooper_edit {
  background: #f2f5fa;
  padding: 10px;
  height: 150px !important;
}
.upload_image {
  position: relative;
}
.image {
  display: block;
  width: 150px;
  height: 100px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  width: 150px;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #7e5493;
}
.upload_image:hover .overlay {
  opacity: 1;
}
.icon {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.icon i {
  cursor: pointer;
  margin-left: 5px;
  padding: 10px;
  background: #e4e4e4;
  border-radius: 5px;
  color: #157efb;
  height: 42px;
  transition: 0.2s;
}
.icon i:hover {
  transition: 0.2s;
  color: #157efb;
}
.show_on_front {
  font-size: 30px;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}
.Container {
  width: 100% !important;
  min-height: calc(100vh - 150px);

  max-height: calc(100vh - 150px) !important;
  overflow-y: scroll;
}
.hooper {
  height: 100px;
}
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
input[type="checkbox"] {
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  padding: 10px;
}
label {
  margin-top: 20px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
.input,
.choosed_keywords {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
  padding-left: 10px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.delete {
  background: #f02849;
  border-radius: 0px !important;
}
.delete i {
  color: white;
}
.add_comp i {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}
table {
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
select {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
