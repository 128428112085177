<template>
  <div class="medicaments">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_exclusibe_Brands.add_exclusibe_brands")
    }}</b-button>
    <b-modal id="modal-xl" v-model="modalShow" size="xl" hide-footer>
      <form @submit.prevent="addMedicaments()">
        <div class="text_editor">
          <label for="">{{ $t("admin.title") }} (KA)</label>
          <b-form-input v-model="form.titleKA"></b-form-input>
          <label for="">{{ $t("admin.title") }} (EN)</label>
          <b-form-input v-model="form.titleEN"></b-form-input>
        </div>
        <div class="text_editor">
          <label for="">
            {{ $t("add_exclusibe_Brands.internationalTitle") }} (KA)
          </label>
          <vue-editor
            v-model="form.additionalInformation.internationalTitleKA"
          ></vue-editor>
          <label for="">
            {{ $t("add_exclusibe_Brands.internationalTitle") }} (EN)
          </label>
          <vue-editor
            v-model="form.additionalInformation.internationalTitleEN"
          ></vue-editor>
        </div>
        <div class="text_editor">
          <label for="">
            {{ $t("add_exclusibe_Brands.clinicAndPharmacyGroup") }} (KA)
          </label>
          <vue-editor
            v-model="form.additionalInformation.ClinicAndPharmacyGroupKA"
          ></vue-editor>
          <label for="">
            {{ $t("add_exclusibe_Brands.clinicAndPharmacyGroup") }} (EN)
          </label>
          <vue-editor
            v-model="form.additionalInformation.ClinicAndPharmacyGroupEN"
          ></vue-editor>
        </div>
        <div class="text_editor">
          <label for=""
            >{{ $t("add_exclusibe_Brands.ownerCompany") }} (KA)</label
          >
          <vue-editor
            v-model="form.additionalInformation.ownerCompanyKA"
          ></vue-editor>
          <label for=""
            >{{ $t("add_exclusibe_Brands.ownerCompany") }} (EN)</label
          >
          <vue-editor
            v-model="form.additionalInformation.ownerCompanyEN"
          ></vue-editor>
        </div>
        <div class="text_editor">
          <label for=""
            >{{ $t("add_exclusibe_Brands.ownerCountry") }} (KA)</label
          >
          <vue-editor
            v-model="form.additionalInformation.ownerCountryKA"
          ></vue-editor>
          <label for=""
            >{{ $t("add_exclusibe_Brands.ownerCountry") }} (EN)</label
          >
          <vue-editor
            v-model="form.additionalInformation.ownerCountryEN"
          ></vue-editor>
        </div>
        <div class="text_editor">
          <label for=""
            >{{ $t("add_exclusibe_Brands.releaseForm") }} (KA)</label
          >
          <vue-editor
            v-model="form.additionalInformation.ReleaseFormKA"
          ></vue-editor>
          <label for=""
            >{{ $t("add_exclusibe_Brands.releaseForm") }} (EN)</label
          >
          <vue-editor
            v-model="form.additionalInformation.ReleaseFormEN"
          ></vue-editor>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("add_exclusibe_Brands.issuance") }} (KA)</label>
          <vue-editor
            v-model="form.additionalInformation.issuanceKA"
          ></vue-editor>
          <label for="">{{ $t("add_exclusibe_Brands.issuance") }} (EN)</label>
          <vue-editor
            v-model="form.additionalInformation.issuanceEN"
          ></vue-editor>
        </div>
        <div class="text_editor">
          <label for=""
            >{{ $t("add_exclusibe_Brands.productDescription") }} (KA)</label
          >
          <vue-editor v-model="form.productDescriptionEN"></vue-editor>
          <label for=""
            >{{ $t("add_exclusibe_Brands.productDescription") }} (EN)</label
          >
          <vue-editor v-model="form.productDescriptionKA"></vue-editor>
        </div>
        <div class="text_editor">
          <label for=""
            >{{ $t("add_exclusibe_Brands.composition") }} (KA)</label
          >
          <vue-editor v-model="form.compositionKA"></vue-editor>
          <label for=""
            >{{ $t("add_exclusibe_Brands.composition") }} (EN)</label
          >
          <vue-editor v-model="form.compositionEN"></vue-editor>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("add_exclusibe_Brands.display") }} (KA)</label>
          <vue-editor v-model="form.displayKA"></vue-editor>
          <label for="">{{ $t("add_exclusibe_Brands.display") }} (EN)</label>
          <vue-editor v-model="form.displayEN"></vue-editor>
        </div>
        <div class="text_editor">
          <label for=""
            >{{ $t("add_exclusibe_Brands.termsAndCondition") }} (KA)</label
          >
          <vue-editor v-model="form.termsAndConditionKA"></vue-editor>
          <label for=""
            >{{ $t("add_exclusibe_Brands.termsAndCondition") }} (EN)</label
          >
          <vue-editor v-model="form.termsAndConditionEN"></vue-editor>
        </div>
        <div class="text_editor">
          <label for=""> {{ $t("admin.keywords") }}</label>
          <i class="fal fa-plus-square" @click="add_component()"></i>
          <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
            <input
              class="input add_component"
              type="text"
              v-model="comp[i]"
              @change="get_value($event)"
            />
            <i class="fal fa-times-square delete_comp" @click="delete_comp"></i>
          </div>
        </div>
        <div>
          <label for="">{{ $t("admin_sidebar.category") }}</label>
          <select name="" id="" v-model="form.category" required>
            <option
              :value="category.id"
              v-for="category in allCategory"
              :key="category.id"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
        <div>
          <label for="">{{ $t("admin.activate_on_front") }}</label>
        </div>
        <input class="checkbox" type="checkbox" v-model="form.show" />
        <div>
          <label for="">{{ $t("admin.image") }}</label>
          <input
            type="file"
            style="margin: 10px 0"
            accept="image/jpg, image/png, image/jpeg"
            multiple
            class="input"
            required
            @change="previewFiles($event)"
          />
        </div>
        <label for="">{{ $t("admin.file") }}</label>
        <input
          type="file"
          style="margin: 10px 0"
          class="input"
          @change="attachFile($event)"
        />
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  components: {
    VueEditor,
    spinner,
  },
  props: {
    getMedicaments: { type: Function },
  },
  name: "login",
  data() {
    return {
      form: {
        show: false,
        keywords: [],
        titleKA: "",
        titleEN: "",
        additionalInformation: {
          internationalTitleKA: "",
          internationalTitleEN: "",
          ClinicAndPharmacyGroupEN: "",
          ClinicAndPharmacyGroupKA: "",
          ownerCompanyKA: "",
          ownerCompanyEN: "",
          ownerCountryKA: "",
          ownerCountryEN: "",
          ReleaseFormKA: "",
          ReleaseFormEN: "",
          issuanceKA: "",
          issuanceEN: "",
        },
        productDescriptionEN: "",
        productDescriptionKA: "",
        compositionEN: "",
        compositionKA: "",
        displayKA: "",
        displayEN: "",
        termsAndConditionEN: "",
        termsAndConditionKA: "",
        category: "",
      },
      images: null,
      attach_file: null,
      file_name: null,
      allCategory: [],
      components: [{ comp: "comp" }],
      isLoading: false,
      modalShow: false,
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    previewFiles(e) {
      const image = e.target.files;
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.images = image;
    },
    attachFile(e) {
      const file = e.target.files[0];
      const fileName = e.target.files[0].name;
      this.attach_file = file;
      this.file_name = fileName;
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    get_value(e) {
      this.form.keywords.push(e.target.value);
    },
    getCategory() {
      axios.get(`${env.host}/get/all/categories/admin`).then((res) => {
        res.data.item.map((item) => {
          this.allCategory.push({
            name: item.titleKA,
            id: item._id,
          });
        });
      });
    },
    addMedicaments() {
      this.isLoading = true;
      axios.post(`${env.host}/add/products`, this.form).then((res) => {
        if (res.data._id) {
          for (let i = 0; i < this.images.length; i++) {
            const formData = new FormData();
            formData.append("image", this.images[i]);
            axios
              .post(
                `${env.host}/add/image/products/${res.data._id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(() => {
                this.isLoading = false;
                this.modalShow = false;
                this.emptyValues();
                setTimeout(() => {
                  this.getMedicaments();
                }, 1000);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          const formData_attach = new FormData();
          formData_attach.append("fileName", this.file_name);
          formData_attach.append("attachment", this.attach_file);
          axios
            .post(
              `${env.host}/add/attachment/products/${res.data._id}`,
              formData_attach,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                icon: "success",
                text: this.$t("success.success_add"),
              });
              this.isLoading = false;
              this.modalShow = false;
              this.emptyValues();
              setTimeout(() => {
                this.getMedicaments();
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        this.isLoading = false;
      });
    },
    emptyValues() {
      this.form.show = false;
      this.components = [{ comp: "comp" }];
      this.form.titleKA = "";
      this.form.titleEN = "";
      this.form.additionalInformation.internationalTitleKA = "";
      this.form.additionalInformation.internationalTitleEN = "";
      this.form.additionalInformation.ClinicAndPharmacyGroupEN = "";
      this.form.additionalInformation.ClinicAndPharmacyGroupKA = "";
      this.form.additionalInformation.ownerCompanyKA = "";
      this.form.additionalInformation.ownerCompanyEN = "";
      this.form.additionalInformation.ownerCountryKA = "";
      this.form.additionalInformation.ownerCountryEN = "";
      this.form.additionalInformation.ReleaseFormKA = "";
      this.form.additionalInformation.ReleaseFormEN = "";
      this.form.additionalInformation.issuanceKA = "";
      this.form.additionalInformation.issuanceEN = "";
      this.form.productDescriptionEN = "";
      this.form.productDescriptionKA = "";
      this.form.compositionEN = "";
      this.form.compositionKA = "";
      this.form.displayKA = "";
      this.form.displayEN = "";
      this.form.termsAndConditionEN = "";
      this.form.termsAndConditionKA = "";
      this.form.category = "";
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.input {
  border-radius: 0px;

  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-left: 10px;
  height: 40px;
}
.medicaments {
  margin-bottom: 10px;
}
label {
  margin-top: 20px;
}
select {
  border-radius: 0px;

  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;

  cursor: pointer;
  outline: none;
  color: white;
}
i {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}
.add_component {
  margin-bottom: 20px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
</style>
